<template>
  <el-form
      ref="clientInfoFormRef"
      :model="clientInfoForm"
      label-position="top">
    <el-form-item label="企业名称" prop="name">
      <el-input
          v-model="clientInfoForm.name"
          placeholder="请填写营业执照中的企业名称"
          size="small"
          disabled
      />
    </el-form-item>
    <el-form-item label="统一社会信用代码" prop="reg_num">
      <el-input
          v-model="clientInfoForm.reg_num"
          size="small"
          disabled
      />
    </el-form-item>
    <el-form-item label="公司注册地址" prop="address">
      <el-input
          v-model="clientInfoForm.address"
          size="small"
          disabled
      />
    </el-form-item>
    <el-form-item label="法人" prop="person">
      <el-input
          v-model="clientInfoForm.person"
          size="small"
          disabled
      />
    </el-form-item>
  </el-form>
</template>

<script setup>
import {ref} from "vue";

const props = defineProps({
  company: {required: true},
  editable: {required: true},
})

const initData = () => {
  return {
    name: props.company && props.company.name ? props.company.name : '',
    reg_num: props.company && props.company.reg_num ? props.company.reg_num : '',
    address: props.company && props.company.address ? props.company.address : '',
    person: props.company && props.company.person ? props.company.person : '',
    code: props.company && props.company.code ? props.company.code : '',
  }
}
const clientInfoForm = ref(initData())

const refreshData = (data) => {
  clientInfoForm.value = data
}

defineExpose({refreshData})
</script>

<style scoped>

</style>