import axios from '@/request/http'
import base from '@/request/base'

export const addContactPerson = (params) => {
  return axios.post(`${base.admin_url}/cloud/user/add_contact_person`, params)
}

export const deleteContactPerson = (params) => {
  return axios.post(`${base.admin_url}/cloud/user/del_contact_person`, params)
}
