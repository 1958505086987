<template>
  <el-form ref="formRef" inline :rules="rules" :model="form">
    <el-row :gutter="20" v-for="(item, index) in form.contact" :key="index">
      <el-form-item label="姓名" label-width="50px" :prop="`contact[${index}].value`"
        :rules="{ required: true, message: '请输入姓名', trigger: 'blur' }">
        <el-input v-model="item.value" :disabled="!editable && index <= users.length - 1" placeholder="请输入姓名" size="small"
          clearable />
      </el-form-item>
      <el-form-item label="手机号" label-width="60px" :prop="`contact[${index}].value1`"
        :rules="{ required: true, message: '请输入手机号', trigger: 'blur' }">
        <el-input v-model="item.value1" :disabled="!editable && index <= users.length - 1" placeholder="请输入手机号"
          size="small" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="text" circle @click="addItem" v-if="index == 0 && editable && form.contact.length < 3">
          <i class="el-icon-circle-plus"></i>
        </el-button>
        <el-button type="text" circle @click="removeItem(item.id)" v-if="index > 0 && editable">
          <i class="el-icon-remove"></i>
        </el-button>
        <span class="contact-button" v-if="form.contact.length !== 1 && !editable && addIndex !== index"
          @click="deleteContact(item, index)">
          删除联系人
        </span>
        <span class="contact-button" v-if="addIndex === index" @click="saveContact(item, index)"
          style="margin-right: 10px;">
          保存新联系人
        </span>
        <span class="contact-button" v-if="addIndex === index" @click="cancelContact(index)">
          取消
        </span>
      </el-form-item>
    </el-row>
  </el-form>
</template>

<script setup>
import { addContactPerson, deleteContactPerson } from '@/request/api/client-info'
import { ref, reactive } from "vue";
import { nanoid } from "nanoid";
import { ElMessage, ElMessageBox } from "element-plus";

const props = defineProps({
  editable: { required: true },
  userinfo: { required: true },
  users: { type: Array }
})

const formRef = ref()
const addIndex = ref(null) // 新增联系人的索引

const initForm = () => {
  let userList = props.users && props.users.length > 0 ? props.users : [{
    value: props.userinfo ? props.userinfo('name') : '',
    value1: props.userinfo ? props.userinfo('phone') : '',
  }]

  let result = []
  userList.forEach((user) => {
    result.push(Object.assign({ id: nanoid() }, user))
  })
  return result
}

const form = ref({ contact: initForm() })
const rules = ref({})

const addItem = () => {
  if (form.value.length >= 3) return ElMessage.warning('最多可添加3个企业联系人')
  form.value.contact.push({ id: nanoid(), value: '', value1: '' })
}

const removeItem = (id) => {
  form.value.contact = form.value.contact.filter(item => item.id !== id)
}

const getForm = () => {
  return form.value.contact
}

const deleteContact = (item, index) => {
  if (props.users.length === 1) return ElMessage.error('请至少保留1个联系人')
  ElMessageBox.confirm('是否确认删除此联系人？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning',
  }).then(() => {
    deleteContactPerson({ id: parseInt(item.id) }).then(res => {
      if (res.code === 200) {
        ElMessage.success('删除联系人成功')
        removeItem(item.id)
        addIndex.value -= 1
        window.location.reload()
      }
    })
  }).catch(() => { })
}

const saveContact = (item, index) => {
  if (!item.value || !item.value1) return ElMessage.warning('请填写完整信息')
  addContactPerson({
    name: item.value,
    phone: parseInt(item.value1),
  }).then(res => {
    if (res.code === 200) {
      ElMessage.success('保存联系人成功')
    }
    addIndex.value = null
    window.location.reload()
  })
}

const cancelContact = (index) => {
  removeItem(form.value.contact[index].id)
  addIndex.value = null
}

const addContact = () => {
  if (form.value.contact.length >= 3) return ElMessage.error('最多可以保存3个企业联系人')
  if (addIndex.value !== null) return ElMessage.warning('请先保存当前联系人')
  addItem()
  addIndex.value = form.value.contact.length - 1
}
defineExpose({ formRef, getForm, addContact })

</script>

<style scoped>
.el-button i {
  font-size: 20px;
}

.contact-button {
  color: #409EFF;
  cursor: pointer;
  line-height: 36px;
}
</style>