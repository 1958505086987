<template>
  <el-form ref="clientFormRef" :model="clientForm" :rules="rules" label-position="top">
    <el-form-item label="你的行业类型" prop="a_id">
      <el-radio-group v-model="clientForm.a_id">
        <template v-for="item in [
          { id: 1, label: '电商', icon: 'icon-dianshang' },
          { id: 2, label: '游戏', icon: 'icon-youxijingji' },
          { id: 3, label: 'APP', icon: 'icon-android' },
        ]" :key="item.id">
          <el-radio border :label="item.id" :disabled="!editable">
            <i class="iconfont icon-yiwancheng" v-show="!editable && clientForm.a_id == item.id ? true : false"></i>
            <el-space> <i :class="'iconfont ' + item.icon"></i>{{ item.label }} </el-space>
          </el-radio>
        </template>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="你是否已经和MMCloud合作" prop="is_cooperate" class="cooperateItem">
      <el-radio-group v-model="clientForm.is_cooperate" @change="(val) => resetClientForm()">
        <template v-for="item in [
          { id: '已经签约', label: '已经签约', icon: 'icon-yijingwanchengdexiangmu' },
          { id: '未签约', label: '未签约', icon: 'icon-weiwancheng' },
        ]" :key="item.id">
          <el-radio border :label="item.id" :disabled="!editable">
            <i class="iconfont icon-yiwancheng"
              v-show="!editable && clientForm.is_cooperate == item.id ? true : false"></i>
            <el-space> <i :class="'iconfont ' + item.icon"></i>{{ item.label }} </el-space>
          </el-radio>
        </template>
        <el-alert title="提示" description="" type="info" show-icon v-if="editable">
          <template #default>
            如果您已经有与MMCloud签署的合同，请选择已签约，上传合同完成认证；若没有，请选择未签约
          </template>
        </el-alert>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="您的营业执照是否是大陆营业执照" prop="is_mainland" v-if="'未签约' === clientForm.is_cooperate">
      <el-radio-group v-model="clientForm.is_mainland" @change="(val) => resetClientForm()">
        <template v-for="item in [
          { id: 1, label: '是', icon: 'icon-weiwancheng' },
          { id: 2, label: '非大陆', icon: 'icon-yijingwanchengdexiangmu' },
        ]" :key="item.id">
          <el-radio border :label="item.id" :disabled="!editable">
            <i class="iconfont icon-yiwancheng"
              v-show="!editable && clientForm.is_mainland == item.id ? true : false"></i>
            <el-space> <i :class="'iconfont ' + item.icon"></i>{{ item.label }} </el-space>
          </el-radio>
        </template>
      </el-radio-group>
    </el-form-item>
    <el-form-item prop="img">
      <template #label>
        <span class="Text">你的企业名称
          <span v-if="editable" class="HelpText" style="margin-left: 10px">请确认提交的企业信息是真实的</span>
        </span>
      </template>
      <el-row :gutter="20">
        <el-col :span="showInfoForm ? 12 : 24">
          <el-space director="vertical" fill width-fill="100" style="width: 100%">
            <upload-file ref="uploadImgRef" :span="showInfoForm ? 24 : 12"
              :action="`${base.admin_url}/cloud/file/uploadfile_company`" :editable="editable" :limit="uploadLimit"
              :url="clientForm.img" :authorization-id="authorizationId" :upload-text="uploadText"
              :show-example="'已经签约' === clientForm.is_cooperate"
              :preview-src-list="4 === company.status || editable ? previewList : clientForm.img"
              @handleRemove="clientForm.img = ''" @handleSuccess="handleSuccess($event, true)" />
          </el-space>
        </el-col>
        <el-col :span="12" v-if="showInfoForm">
          <client-info-form ref="infoFormRef" :editable="editable" :company="infoForm" />
        </el-col>
      </el-row>
    </el-form-item>
    <el-row v-if="showNameInput">
      <el-col :span="showInfoForm ? 24 : 12">
        <el-form-item prop="name">
          <el-input size="small" :placeholder="2 === clientForm.is_mainland ? '请输入您的公司注册证明书上的主体名称' : '请填写合同上的企业名称'
            " v-model="clientForm.name" :disabled="!editable" />
        </el-form-item>
      </el-col>
    </el-row>
    <el-row v-if="alertTitle">
      <el-col :span="12">
        <el-alert :title="alertTitle" :type="alertType" show-icon :closable="false"> </el-alert>
        <!-- <img src="@/assets/images/clientInfo/contact.png" alt="在线顾问二维码" style="width: 200px; height: 200px" v-if="alertTitle === -->
        <!-- '企业认证审核中,请耐心等待1个工作日，若超时未审核，请联系邀请您注册的商务或YinoCloud在线顾问小云' -->
        <!-- " /> -->
      </el-col>
    </el-row>
    <el-row v-if="clientForm.code == 400" style="margin-top: 10px">
      <el-col :span="12">
        <el-alert type="error" show-icon :closable="false">
          <template #title>
            <span class="Text">该企业已被认证，点击进行
              <span class="brandColor" style="cursor: pointer" @click="applyBtn">企业认领</span>
            </span>
          </template>
        </el-alert>
      </el-col>
    </el-row>
    <el-link type="primary" style="margin-top: 20px" v-if="'已经签约' === clientForm.is_cooperate && 2 == company.status"
      @click="cancelLicense" :underline="false">
      <el-space>
        <i class="iconfont icon-zizhutuiguang" />
        合同上传错误？点击重新认证
      </el-space>
    </el-link>
    <template v-if="'未签约' === clientForm.is_cooperate">
      <el-form-item prop="authorize_img" class="authorizeItem">
        <template #label>
          企业开通广告账户授权书
          <template v-if="editable">
            <span class="HelpText">请下载原件并签署盖章后，上传扫描件</span>
            <el-button type="text" style="height: auto" @click="downloadFile"><span
                class="LinkText">点击下载授权书原件</span></el-button>
          </template>
        </template>
        <upload-file ref="uploadAuthImgRef" :action="`${base.admin_url}/cloud/file/uploadfile_authorize`"
          :editable="editable" :limit="1" :url="clientForm.authorize_img" :authorization-id="3" upload-text="点击上传授权书扫描件"
          :show-example="true" :preview-src-list="previewList" @handleRemove="clientForm.authorize_img = ''"
          @handleSuccess="handleSuccess($event, false, 'authorize_img')" />
        <el-alert title="快速通过的Tips" description="" type="info" show-icon>
          <template #default> 授权书内容完善，落款的盖章和授权书上公司名称保持一致，且公章清晰真实。 </template>
        </el-alert>
      </el-form-item>
      <!--      <el-form-item prop="rebate_img" label="渠道用户返点协议" v-if="$store.getters.userInfo.exc !== 'shoplazza'">-->
      <!--        <template #label>-->
      <!--          渠道用户返点协议-->
      <!--          <template v-if="editable">-->
      <!--            <span class="HelpText">请下载原件并签署盖章后，上传扫描件</span>-->
      <!--            <el-button type="text" style="height: auto;" @click="downloadFile"-->
      <!--            ><span class="LinkText">下载返点协议原件</span></el-button>-->
      <!--          </template>-->
      <!--        </template>-->
      <!--        <upload-file-->
      <!--            ref="uploadAuthImgRef"-->
      <!--            :action="`${base.admin_url}/cloud/file/uploadfile_rebate`"-->
      <!--            :editable="editable"-->
      <!--            :limit="1"-->
      <!--            :url="clientForm.rebate_img"-->
      <!--            :authorization-id="0"-->
      <!--            upload-text="点击上传返点协议扫描件"-->
      <!--            :show-example="true"-->
      <!--            :preview-src-list="getRebateImg"-->
      <!--            @handleRemove="clientForm.rebate_img = ''"-->
      <!--            @handleSuccess="handleSuccess($event, false, 'rebate_img')"-->
      <!--        />-->
      <!--      </el-form-item>-->
      <el-form-item v-if="company.qudao_type !== 1">
        <template #label>
          <div class="contact-header">
            <div class="Text">企业联系人<span class="HelpText">（请至少填写1个企业联系人，最多3个）</span></div>
            <el-link type="primary" :underline="false" v-if="!editable" @click="contactUserRef.addContact()">
              新增联系人
            </el-link>
          </div>
        </template>
        <contact-user ref="contactUserRef" :editable="editable" :userinfo="userinfo"
          :users="company.contact_person"></contact-user>
      </el-form-item>
    </template>
    <el-form-item v-if="editable">
      <el-space>
        <el-checkbox v-model="clientForm.agree" label=""></el-checkbox>
        <span class="Text"> 我已了解：该信息仅供MMCloud进行企业认证使用</span>
      </el-space>
      <br />
      <el-button type="primary" size="small" style="width: auto; height: auto; padding: 9px 15px; margin-top: 15px"
        @click="submitIdentify" :disabled="clientForm.agree ? false : true" v-if="editable">提交认证
      </el-button>
    </el-form-item>
    <el-form-item v-if="'已经签约' === clientForm.is_cooperate && !editable && company.qudao_type !== 1"
      style="margin-top: 10px;">
      <template #label>
        <div class="contact-header">
          <div class="Text">企业联系人<span class="HelpText">（请至少填写1个企业联系人，最多3个）</span></div>
          <el-link type="primary" :underline="false" @click="contactUserRef.addContact()">
            新增联系人
          </el-link>
        </div>
      </template>
      <contact-user ref="contactUserRef" :editable="editable" :userinfo="userinfo"
        :users="company.contact_person"></contact-user>
    </el-form-item>
  </el-form>

  <el-dialog custom-class="addWXUser" v-if="'add-wx-user' === dialogType" v-model="showDialog" title="" width="430px"
    @close="closeAndRefresh">
    <img class="server_qrcode" :src="live_code" alt="" />
  </el-dialog>
</template>

<script setup>
import { computed, getCurrentInstance, ref } from 'vue'
import base from '@/request/base'
import { ElMessage, ElMessageBox } from 'element-plus'
import UploadFile from './UploadFile'
import ClientInfoForm from './ClientInfoForm'
import ContactUser from './ContactUser'
import EmailBind from './EmailBind'

const props = defineProps({
  company: { required: true },
  userinfo: { required: true, type: Function },
})

const dialogType = ref('')
const showDialog = ref(false)
const live_code = ref('')

const { proxy } = getCurrentInstance()
const uploadImgRef = ref(null)
const uploadAuthImgRef = ref(null)
const contactUserRef = ref(null)
const initClientForm = () => {
  return {
    a_id: props.company.type ? parseInt(props.company.type) : 1,
    name: props.company.company ? props.company.company : '',
    is_cooperate: props.company.is_cooperate === 2 ? '未签约' : '已经签约',
    is_mainland: parseInt((props.company.is_mainland ? props.company.is_mainland : 1).toString()),
    img: props.company.url ? props.company.url : '', //营业执照or合同
    authorize_img: props.company.authorize_img ? props.company.authorize_img : '', //授权书
    // rebate_img: props.company.rebate_img ? props.company.rebate_img : '', //返点协议
    status: props.company.status ? props.company.status : 0,
    agree: false,
    code: 0,
  }
}

const clientFormRef = ref(null)
const clientForm = ref(initClientForm())
const rules = ref({
  a_id: [{ required: true, message: '请选择行业类型', trigger: 'blur' }],
  name: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
  is_cooperate: [{ required: true, message: '请选择签约状态', trigger: 'blur' }],
  is_mainland: [{ required: true, message: '请选择营业执照类型', trigger: 'blur' }],
  img: [{ required: true, message: '请上传正确清晰的图片', trigger: 'blur' }],
  authorize_img: [{ required: true, message: '请上传授权书', trigger: 'blur' }],
  // rebate_img: [{required: true, message: '请上传返点协议', trigger: 'blur'}],
})

const initInfoForm = () => {
  return {
    name: props.company.data && props.company.data.name ? props.company.data.name : '',
    reg_num: props.company.data && props.company.data.reg_num ? props.company.data.reg_num : '',
    address: props.company.data && props.company.data.address ? props.company.data.address : '',
    person: props.company.data && props.company.data.person ? props.company.data.person : '',
  }
}
const infoForm = ref(initInfoForm())
const infoFormRef = ref(null)

const uploadText = computed(() => {
  if ('未签约' === clientForm.value.is_cooperate)
    return clientForm.value.is_mainland === 1 ? '点击上传营业执照' : '点击上传公司注册证明书（非大陆）'
  return '点击上传合作合同首页'
})
const uploadLimit = computed(() => {
  if (clientForm.value.is_cooperate === '已经签约') return 1
  return 2
})

const authorizationId = computed(() => {
  if (clientForm.value.is_cooperate === '已经签约') return 1
  if (clientForm.value.is_cooperate === '未签约') return 2
})

const previewList = computed(() => {
  if ('已经签约' === clientForm.value.is_cooperate)
    return editable.value ? '/images/authorize/company.png' : clientForm.value.img
  if (1 === clientForm.value.is_mainland)
    return 4 === props.company.status || editable.value
      ? '/images/authorize/mainland_authorize_1.jpeg'
      : clientForm.value.authorize_img
  if (2 === clientForm.value.is_mainland)
    return 4 === props.company.status || editable.value
      ? '/images/authorize/mainland_authorize_2.jpeg'
      : clientForm.value.authorize_img
  return ''
})

// const getRebateImg = computed(() => {
//   return 4 === props.company.status || editable.value ? '/images/authorize/mainland_authorize_1.jpeg' : clientForm.value.rebate_img
// })

const showNameInput = computed(() => {
  if ('未签约' === clientForm.value.is_cooperate) return 2 === clientForm.value.is_mainland
  return !editable.value || [100, 200].indexOf(parseInt(clientForm.value.code.toString())) >= 0
})

const editable = computed(() => {
  return props.company.status !== 1 && props.company.status !== 2
})

const showInfoForm = computed(() => {
  if (clientForm.value.is_cooperate === '已经签约') return false
  return 1 === clientForm.value.is_mainland
})

const alertTitle = computed(() => {
  switch (proxy.$store.getters.userInfo.exc) {
    case 'shoplazza':
      if (props.company && props.company.status === 1) return '企业认证成功，您可以充值开户了'
      if (props.company && props.company.status === 2)
        return '企业认证审核中,请耐心等待1个工作日，并请保持电话畅通，我们的工作人员会跟您联系'
      if (props.company && props.company.status === 4)
        return '你的认证已被驳回，理由是' + (props.company.reason ? props.company.reason : '')
      return ''
    case 'qbit':
      if (props.company && props.company.status === 1) return '企业认证成功，您可以进行个号充值了'
      if (props.company && props.company.status === 2)
        return '企业认证审核中,请耐心等待1个工作日，若超时未审核，请联系邀请您注册的对接人'
      if (props.company && props.company.status === 4)
        return '你的认证已被驳回，理由是' + (props.company.reason ? props.company.reason : '')
      return ''
    default:
      if (props.company && props.company.status === 1) return '企业认证成功，您可以充值开户了'
      if (props.company && props.company.status === 2)
        return '企业认证审核中,请耐心等待1个工作日，若超时未审核，请联系邀请您注册的商务'
      if (props.company && props.company.status === 4)
        return '你的认证已被驳回，理由是' + (props.company.reason ? props.company.reason : '')
      return ''
  }
})

const alertType = computed(() => {
  if (props.company && props.company.status === 1) return 'success'
  if (props.company && props.company.status === 2) return 'warning'
  if (props.company && props.company.status === 4) return 'error'
})

const submitIdentify = async () => {
  if (!clientFormRef.value) return
  const result = await clientFormRef.value.validate()
  if (result !== true) return

  if ('未签约' === clientForm.value.is_cooperate) {
    const result = await contactUserRef.value.formRef.validate()
    if (result !== true) return
  }

  let data = {
    name: clientForm.value.name,
    a_id: clientForm.value.a_id,
    is_cooperate: clientForm.value.is_cooperate,
    authorize_img: clientForm.value.authorize_img,
    // rebate_img: clientForm.value.rebate_img,
    contact_person: clientForm.value.is_cooperate === '未签约' ? contactUserRef.value.getForm() : '',
    is_mainland: clientForm.value.is_mainland,
    type: 1,
  }

  const { data: res } = await proxy.$http.post('cloudfb/company_attest', data)
  if (res.code === 100) return ElMessage.error(`${res.msg}`)
  if (res.code === 300) return proxy.$router.push('/login')
  if (res.code == 200) {
    ElMessage.success(`${res.msg}`)
    let trace = window.localStorage.getItem('trace')
    if (trace) {
      const { data: res } = await proxy.$http.post('login/trace', {
        key: trace,
        type: '注册',
        token: res.user.token,
      })
    }
    // 将登陆成功后的token，保存到客户端的localStorage中
    window.localStorage.setItem('userInfo', JSON.stringify(res.user))
    proxy.$store.dispatch('EDIT_USERINFO', res.user)
    addWxUser(res, true)
    return
  }

  if (res.code == 400) clientForm.value.code = 400
}

const addWxUser = async (res, refresh = true) => {
  if (!res.user || !res.user.company || !res.user.phone || res.user.exc === 'qbit') {
    window.localStorage.setItem('userInfo', JSON.stringify(res.user))
    proxy.$store.dispatch('EDIT_USERINFO', res.user)
    if (refresh) closeAndRefresh()
    return
  }
  window.localStorage.setItem('userInfo', JSON.stringify(res.user))
  proxy.$store.dispatch('EDIT_USERINFO', res.user)
  closeAndRefresh()
}

const closeAndRefresh = () => {
  dialogType.value = ''
  window.location.reload()
}

const resetClientForm = () => {
  let initFormValue = initClientForm()
  if (uploadImgRef.value && uploadImgRef.value.refreshData) {
    uploadImgRef.value.refreshData()
  }
  if (uploadAuthImgRef.value && uploadAuthImgRef.value.refreshData) {
    uploadAuthImgRef.value.refreshData()
  }

  if (
    initFormValue.is_cooperate !== clientForm.value.is_cooperate ||
    initFormValue.is_mainland !== clientForm.value.is_mainland
  ) {
    infoForm.value = {}
    clientForm.value.img = ''
    clientForm.value.authorize_img = ''
    clientForm.value.name = ''
  } else {
    infoForm.value = initInfoForm()
    clientForm.value.img = props.company.url ? props.company.url : ''
    clientForm.value.authorize_img = props.company.authorize_img ? props.company.authorize_img : ''
    clientForm.value.name = props.company.company ? props.company.company : ''
  }

  if (infoFormRef.value) infoFormRef.value.refreshData(infoForm.value)
}

const handleSuccess = (res, updateCode = true, field = undefined) => {
  if (res.code == 100) ElMessage.error(res.msg)
  if (updateCode) {
    //合同or执照更新
    if ('未签约' === clientForm.value.is_cooperate && clientForm.value.is_mainland === 1) {
      if (!res.data) {
        ElMessage.error('未识别到执照信息，请重新上传')
        infoForm.value = {}
      } else {
        infoForm.value = res.data
        clientForm.value.name = res.data.name ? res.data.name : ''
        clientForm.value.img = res.path ? res.path : ''
      }
      if (infoFormRef.value) infoFormRef.value.refreshData(infoForm.value)
    } else {
      //已签约
      infoForm.value = {}
      if (infoFormRef.value) infoFormRef.value.refreshData(infoForm.value)
      clientForm.value.img = res.path ? res.path : ''
      clientForm.value.name = res.name ? res.name : res.data && res.data.name ? res.data.name : ''
    }
  } else {
    //授权书更新
    if (undefined !== field) clientForm.value[field] = res.path1 ? res.path1 : ''
  }

  clientForm.value.code = res.code && updateCode ? parseInt(res.code.toString()) : 0
}

const cancelLicense = async () => {
  const { data: res } = await proxy.$http.post('cloudfb/cancel_attest', {
    token: props.userinfo('token'),
  })
  if (res.code == 100) {
    ElMessage.error(`${res.msg}`)
  }
  if (res.code == 300) {
    proxy.$router.push('/login')
    return
  }
  if (res.code == 200) {
    // 将登陆成功后的token，保存到客户端的localStorage中
    window.location.reload()
  }
}

const applyBtn = () => {
  ElMessageBox.alert('', '企业认领', {
    confirmButtonText: '收到',
    dangerouslyUseHTMLString: true,
    message: `如果在您进行企业认证的时候，发现这个企业主体已经存在，可以发送邮件至<span class="brandColor">linyu@mmpop.top</span>进行企业认领。
        请在邮件中包含您的营业执照、签约信息、能证明您在这家公司任职的相应信息（如名片），以及其他可以证明您是属于这家企业主体的资料。我们的工作人员会在收到您的邮件后的3个工作日内进行回复。
        `,
  })
}

const downloadFile = () => {
  console.log(proxy.$store.getters.userInfo)
  if (proxy.$store.getters.userInfo.exc === 'shoplazza') return window.open(`document/店匠企业开通广告账户申请函.docx`)
  if (clientForm.value.is_mainland == 1) window.open(`document/企业开通广告账户申请函-大陆.docx`)
  if (clientForm.value.is_mainland == 2) window.open(`document/企业开通广告账户申请函-非大陆.docx`)
}
</script>

<style lang="less" scoped>
.contact-header {
  display: flex;
  align-items: center;

  div {
    margin-right: 20px;
  }
}

:deep(.cooperateItem .el-form-item__content) {
  & .el-alert__icon {
    background-color: #e6f7ff !important;
    color: #2b82ff !important;
  }
}

:deep(.authorizeItem .el-form-item__content) {
  display: flex;
  align-items: center;

  & .el-alert--info.is-light {
    max-width: 400px;
    height: 80px;
  }

  & .el-alert__icon {
    background-color: #e6f7ff !important;
    color: #2b82ff !important;
  }
}

:deep(.el-form-item__content) {
  line-height: normal;
}

:deep(.el-radio-group) {
  display: flex;
}

:deep(.el-form-item__label) {
  padding-bottom: 0 !important;
}

:deep(.iconfont) {
  font-size: 20px;
}

:deep(.el-radio__input) {
  width: 0;
  height: 0;
  opacity: 0;
}

:deep(.el-radio, is-bordered) {
  width: 150px;
  height: 50px;
  justify-content: center;
  margin-right: 0 !important;
}

:deep(.el-radio.is-disabled.is-checked) {
  border-color: var(--el-color-primary) !important;
}

:deep(.el-radio.is-disabled.is-checked .icon-yiwancheng) {
  color: var(--el-color-primary) !important;
}

:deep(.el-radio + .el-radio) {
  margin-left: 10px !important;
}

:deep(i.icon-yiwancheng) {
  position: absolute;
  top: -1px;
  right: 0;
}

:deep(.el-link .el-link--inner) {
  font-size: 14px;
  font-weight: unset !important;
}

:deep(.el-link .el-link--inner .icon-zizhutuiguang) {
  font-size: 16px !important;
}

.el-alert--info.is-light {
  max-width: 600px;
  margin-left: 80px;
  background-color: #e6f7ff !important;
  border: 1px solid #91d5ff !important;
}

:deep(.el-alert__title.is-bold) {
  font-size: 16px;
  font-weight: 400;
  color: #51575a;
}
</style>
<style>
.addWXUser {
  height: 560px !important;
  background: url('../../../../assets/images/popBG.png') no-repeat;
  background-size: 100%;
}

.addWXUser .el-dialog__header {
  padding: 0 !important;
  border: 0 !important;
}

.addWXUser .el-dialog__body {
  padding: 0 !important;
}

.server_qrcode {
  width: 268px;
  height: 268px;
  position: absolute;
  top: 211px;
  left: 50%;
  transform: translateX(-50%);
}
</style>
